<template>
    <div class="cart-section">
        <h2>{{ $t('cart.title') }}</h2>
        <div v-if="cart.length" class="cart-section__inner">
            <div class="wrapper">
                <CartCard
                    class="cart-card"
                    v-for="item in cart"
                    @edit-cart-item="editCartItem(item)"
                    :key="item.id"
                    :mealKit="item.mealKit"
                    :menu-type="item.menuType?.type"
                    :calorie="item.calorie"
                    :period="item.period"
                    :allergens="item.allergens"
                    :additions="item.additions"
                    :additional-allergen="item.additionalAllergen"
                    :amount="item.amount"
                    :total-price="item.totalPrice"
                    :meal-kit-price="item.mealKitPrice"
                    :combination="$filters.getTranslationByKey(item.combination?.name)"
                />
                <DiscountCodeSection class="mb-30" />
                <DiscountPointSection :price="prices?.priceWithoutDiscount" />
            </div>
            <div class="order-section">
                <PriceSection :cart="cart" />
                <div class="btn__wrapper">
                    <CRButton class="btn" @click="toMealKitsPage" pattern="outline">
                        {{ $t('buttons.continueBuying') }}
                    </CRButton>
                    <CRButton class="btn" @click="toCheckOutPage">
                        {{ $t('buttons.checkout') }}
                    </CRButton>
                </div>
            </div>
        </div>

        <div v-else class="empty-page">
            <CartSvg class="icon" />
            <h2>{{ $t('cart.noItemsInCart') }}</h2>
            <p>{{ $t('cart.goToMealKitsSelection') }}</p>
            <CRButton @click="toMealKitsPage">
                {{ $t('navbar.mealKits') }}
            </CRButton>
        </div>
    </div>
</template>

<script>
    import CartCard from '@/components/Cart/CartCard';
    import CRButton from '@/components/Common/Buttons/CRButton.vue';
    import DiscountCodeSection from '@/components/Cart/DiscountCodeSection.vue';
    import DiscountPointSection from '@/components/Cart/DiscountPointSection.vue';
    import PriceSection from '@/components/Cart/PriceSection.vue';
    import CartSvg from '@/assets/icons/navbar/cart-shopping.svg?inline';
    import { cartMixin } from '@/utils/mixins';
    import { mapGetters } from 'vuex';
    import { ConversionEventNameEnum } from '@/utils/enums';

    export default {
        name: 'Cart',
        components: { CartCard, CRButton, PriceSection, CartSvg, DiscountCodeSection, DiscountPointSection },
        mixins: [cartMixin],
        computed: {
            ...mapGetters('cart', ['cart']),
            ...mapGetters('orderPrices', ['prices']),
        },
        methods: {
            toMealKitsPage() {
                this.$router.push({ name: 'mealKits' });
            },

            async toCheckOutPage() {
                this.$router.push({ name: 'checkOutBuyerInfo' });

                fbq('track', ConversionEventNameEnum.INITIATE_CHECK_OUT);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .cart-section {
        margin-top: 25px;
        padding-bottom: 25px;

        h2 {
            margin-bottom: 20px;
        }

        .wrapper {
            margin-bottom: 24px;

            .cart-card {
                margin-bottom: 20px;
            }
        }

        .order-section {
            .btn__wrapper {
                @include row-align-center-justify-end;
                flex-wrap: wrap;

                margin-top: 24px;

                .btn {
                    width: 100%;
                    margin-bottom: 16px;

                    &:last-child {
                        margin-bottom: 0;
                    }
                }
            }
        }

        .empty-page {
            @include column-align-center-justify-center;

            height: calc(100vh - 300px);

            .icon {
                width: 72px;
                height: 64px;

                margin-bottom: 23px;
            }

            h2 {
                text-align: center;
                margin-bottom: 8px;
            }

            p {
                margin-bottom: 12px;
            }
        }
    }

    @include media($md) {
        .cart-section {
            .cart-section__inner {
                @include row-align-start-justify-between;
                flex-wrap: wrap;
            }

            .wrapper,
            .order-section {
                width: 49%;
            }

            h2 {
                width: 100%;
            }

            .order-section {
                .btn__wrapper {
                    .btn {
                        width: auto;
                        margin-bottom: 0;

                        &:last-child {
                            margin-left: 10px;
                        }
                    }
                }
            }
        }
    }

    @include media($lg) {
        .cart-section {
            h2 {
                font-size: $font-34;
                margin-bottom: 30px;
            }
        }
    }

    @include media($xl) {
        .cart-section {
            .wrapper,
            .order-section {
                width: 48%;
            }
        }
    }

    @include media($xxl) {
        .cart-section {
            .wrapper,
            .order-section {
                width: 45%;
            }
        }
    }
</style>
